import React from 'react'
import DiaryTable from './DiaryTable/DiaryTable'
import DiaryPlayerList from './DiaryPlayerList/DiaryPlayerList'
import DiaryActionList from './DiaryActionList/DiaryActionList'
import './Diary.scss'

function Diary({ me, players }) {
  return (
    <div className="Diary p-sm" data-testid="diary">
      <div className="Diary__TableContainer1">
        <div className="Diary__TableContainer2">
          <DiaryTable players={players} />
        </div>
      </div>
      <div className="Diary__Workspace">
        <DiaryPlayerList me={me} players={players} object />
        <DiaryActionList />
        <DiaryPlayerList me={me} players={players} object={false} />
      </div>
    </div>
  )
}

export default Diary
