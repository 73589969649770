import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaTrash } from 'react-icons/fa'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { DIARY_ACTIONS } from '../../../config/diaryActions'
import IconButtonPure from '../../../wal-common/src/components/Icon/IconButtonPure/IconButtonPure'
import { actionCleanDiary } from '../../../store/actions'
import './DiaryTable.scss'

function DiaryTable({ players }) {
  const history = useSelector(({ diary: { history } }) => history)
  const dispatch = useDispatch()

  const playersObj = players.reduce(
    (acc, pl) => ({
      ...acc,
      [pl.id]: pl,
    }),
    {}
  )

  return (
    <div className="DiaryTable">
      <table className="table is-hoverable is-fullwidth is-narrow">
        <TransitionGroup component="tbody">
          {history.map(({ id, objectPlayerId, action, subjectPlayerId }) => (
            <CSSTransition
              appear
              key={id}
              timeout={300}
              classNames="DiaryTable__Item"
            >
              <tr key={id}>
                <td>
                  <IconButtonPure
                    addClasses="has-text-danger"
                    onClick={() => {
                      dispatch(
                        actionCleanDiary({
                          entryId: id,
                        })
                      )
                    }}
                    Icon={FaTrash}
                  />
                </td>
                <td>{playersObj[objectPlayerId].name}</td>
                <td>{DIARY_ACTIONS[action]}</td>
                <td>{playersObj[subjectPlayerId].name}</td>
              </tr>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </table>
    </div>
  )
}

export default DiaryTable
