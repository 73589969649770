import React from 'react'

import Transition from 'react-transition-group/Transition'

const duration = 100

const defaultStyle = {
  display: 'block',
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
}

const Fade = ({ in: inProp, children }) => (
  <Transition in={typeof inProp === 'undefined' ? true : inProp} timeout={duration} appear={true}>
    {state => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
)

export default Fade
