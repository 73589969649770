import React, { useState } from 'react'
import Button from '../../FormElement/Button/Button'
import { ROLE_MAFIA, ROLES } from '../../../config/roles'
import { giveRoles } from '../../../store/actions'

function CardsSelection({ players, gameId }) {
  const playerCount = players.length
  const [mafiaCount, setMafiaCount] = useState('3')

  const addRoles = Object.keys(ROLES).filter((role) => role !== ROLE_MAFIA)
  const defaultAddRoles = addRoles.filter((role) => ROLES[role].default)
  const [selectedAddRoles, setSelectedAddRoles] = useState(defaultAddRoles)

  const handleSelectMafiaCount = (e) => {
    setMafiaCount(e.target.value)
  }

  const handleSelectAddRoles = (e) => {
    setSelectedAddRoles(
      [...e.target.options].filter((o) => o.selected).map((o) => o.value)
    )
  }

  const maxMafia = 5
  const rolesTotal = selectedAddRoles.length + Number.parseInt(mafiaCount, 10)

  return (
    <div>
      <div>Какие роли учаcтвуют в игре?</div>

      <div className="m-t-md">
        <div className="select is-rounded">
          <select
            data-testid="select-mafia-count"
            value={mafiaCount}
            onChange={handleSelectMafiaCount}
            onBlur={handleSelectMafiaCount}
          >
            {Array.from(Array(maxMafia).keys()).map((el, ind) => (
              <option key={el} value={el + 1}>
                {el + 1} мафиозо
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="m-t-md">и выделенные роли:</div>
      <div className="m-t-md">
        <div className="select is-multiple">
          <select
            multiple
            data-testid="select-add-roles"
            size={addRoles.length}
            onChange={handleSelectAddRoles}
            onBlur={handleSelectAddRoles}
            value={selectedAddRoles}
          >
            {addRoles.map((role) => (
              <option key={role} value={role}>
                {ROLES[role].name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="m-t-md">
        <Button
          onClick={() => {
            const roles = {
              [ROLE_MAFIA]: parseInt(mafiaCount),
            }
            selectedAddRoles.forEach((addRole) => {
              roles[addRole] = 1
            })
            giveRoles({ gameId, players, roles })
          }}
          dataTestid="shuffleCards"
          disabled={rolesTotal > playerCount}
          withConfirmation
        >
          раздать карты
        </Button>
      </div>
    </div>
  )
}

export default CardsSelection
