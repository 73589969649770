import React from 'react'
import { DIARY_ACTIONS } from '../../../config/diaryActions'
import './DiaryActionList.scss'
import { useDispatch, useSelector } from 'react-redux'
import { actionSetDiary } from '../../../store/actions'
import { getClasses } from '../../../utils'

const classes = getClasses('DiaryActionList')

function DiaryActionList() {
  const dispatch = useDispatch()
  const { action } = useSelector(({ diary: { current } }) => current)

  return (
    <div {...classes()}>
      {Object.keys(DIARY_ACTIONS).map((act) => (
        <div
          onClick={() => {
            dispatch(actionSetDiary({ action: act }))
          }}
          role="button"
          tabIndex="0"
          data-testid="diary_act"
          key={act}
          {...classes('Item', { active: action === act })}
        >
          {DIARY_ACTIONS[act]}
        </div>
      ))}
    </div>
  )
}

export default DiaryActionList
