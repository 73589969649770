import React, { useState } from 'react'
import LayoutCenter from '../../wal-common/src/components/Layout/LayoutCenter/LayoutCenter'
import Button from '../FormElement/Button/Button'
import Modal from '../../wal-common/src/components/Modal/Modal'
import { ADD_PLAYER } from '../../graphql/queries'
import PlayerList from './PlayerList/PlayerList'
import { useMutation } from '@apollo/react-hooks'
import TypeName from './TypeName/TypeName'
import CardSelection from './CardSelection/CardSelection'
import './App.scss'
import { STATE_ROLES_GIVEN } from '../../config/states'
import Diary from '../Diary/Diary'
import { useDispatch, useSelector } from 'react-redux'
import { actionCleanDiary } from '../../store/actions'

const App = function ({ game, me }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [addPlayer] = useMutation(ADD_PLAYER)
  const myId = useSelector(({ me }) => me.id)
  const dispatch = useDispatch()

  if (!game) {
    return <div data-testid="loader" />
  }

  const { players } = game

  return (
    <>
      {game.state === STATE_ROLES_GIVEN && <Diary me={me} players={players} />}
      {game.state !== STATE_ROLES_GIVEN && (
        <>
          <LayoutCenter>
            <div className="App">
              <div>
                {!me && !game.state ? (
                  <div className="m-b-md">
                    <Button
                      onClick={() => {
                        setModalOpen(true)
                      }}
                      dataTestid="join"
                    >
                      войти в игру
                    </Button>
                  </div>
                ) : null}
                {players ? (
                  <div>
                    <div>
                      {players.length > 0
                        ? `В игре:`
                        : 'В игре пока нет ни одного игрока'}
                    </div>
                    <PlayerList me={me} players={players} />
                  </div>
                ) : null}
              </div>
              {!game.state && me && players.length > 0 ? (
                <div className="m-l-md">
                  <CardSelection gameId={game.id} players={players} />
                </div>
              ) : null}
            </div>
          </LayoutCenter>

          <Modal
            modalActive={modalOpen}
            onClose={() => {
              setModalOpen(false)
            }}
          >
            <TypeName
              onEnter={(playerName) => {
                addPlayer({
                  variables: {
                    gameId: game.id,
                    playerName: playerName,
                    playerId: myId,
                  },
                })
                dispatch(actionCleanDiary())
                setModalOpen(false)
              }}
            />
          </Modal>
        </>
      )}
    </>
  )
}

export default App
