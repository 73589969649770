export const ROLE_MAFIA = 'role_mafia'
export const ROLE_MOMMISAR = 'role_kommisar'
export const ROLE_DOKTOR = 'role_doktor'
export const ROLE_LOVER = 'role_lover'

export const ROLE_CITIZEN_NAME = 'мирный'

export const ROLES = {
  [ROLE_MAFIA]: {
    name: 'мафиозо',
  },
  [ROLE_MOMMISAR]: {
    name: 'коммисар',
  },
  [ROLE_DOKTOR]: {
    name: 'доктор',
    default: true,
  },
  [ROLE_LOVER]: {
    name: 'любовница',
  },
}
