import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import App from '../components/App/App'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import { GET_GAME, ON_ADD_PLAYER } from '../graphql/queries'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { actionGenerateMyId } from '../store/actions'

function Game(props) {
  const gameId = props['*']

  if (!gameId) {
    throw new Error('wrong running point')
  }

  const [game, setGame] = useState(null)

  const dispatch = useDispatch()

  const myId = useSelector(({ me }) => me.id)

  useEffect(() => {
    if (!myId) {
      dispatch(actionGenerateMyId())
    }
  }, [myId, dispatch])

  useQuery(GET_GAME, {
    variables: { id: gameId },
    onCompleted: (data) => {
      if (data) {
        if (data.getGame) {
          setGame(data.getGame)
        } else {
          navigate('/404/')
        }
      }
    },
  })

  useSubscription(ON_ADD_PLAYER, {
    variables: { id: gameId },
    onSubscriptionData: ({ subscriptionData }) => {
      const gameIn = subscriptionData.data.onAddPlayer
      setGame(gameIn)
    },
  })

  const me = game ? game.players.find(({ id }) => id && id === myId) : null

  return (
    <Layout game={game} me={me}>
      <App game={game} me={me} />
    </Layout>
  )
}

export default Game
