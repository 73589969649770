import React from 'react'
import BEMHelper from 'react-bem-helper'
import './Player.scss'

const classes = new BEMHelper({
  name: 'Player',
})

function Player({ player: { name }, ind, showRoleName, onClick, active }) {
  return (
    <div
      {...classes(null, { clickable: !!onClick, active: active }, 'is-size-7')}
      data-testid="player"
      onClick={onClick}
      role="button"
      tabIndex="0"
    >
      {ind + 1}. {name}
      {showRoleName && (
        <p className="help is-success has-text-right">{showRoleName}</p>
      )}
    </div>
  )
}

export default Player
