import React, { Component } from 'react'
import BEMHelper from 'react-bem-helper'
import Fade from '../Transitions/Fade/Fade'
import './Modal.scss'

const classes = new BEMHelper({
  name: 'Modal',
})

class Modal extends Component {
  ref = React.createRef()

  setBodyClass() {
    if (typeof document !== 'undefined') {
      document.documentElement.classList.toggle(
        'hasModalOpen',
        this.props.modalActive,
      )
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.modalActive && !prevProps.modalActive) {
      setTimeout(() => {
        this.ref.current.style.transform = 'scale(1)';
      }, 1)
    } else if (!this.props.modalActive && prevProps.modalActive) {
      setTimeout(() => {
        this.ref.current.style.transform = null;
      }, 1)
    }
  }

  render() {
    const { modalActive, children, onClose, modifier } = this.props

    this.setBodyClass()

    return (
      <span
        ref={this.ref}
        {...classes(null, modifier, { modal: true, 'is-active': modalActive })}
      >
        <Fade in={modalActive}>
          <span className="modal-background"/>
          <span {...classes('Content', null, 'modal-content')}>{children}</span>
          <button
            onClick={onClose}
            {...classes('Close', null, 'modal-close is-large')}
            aria-label="close"
          />
        </Fade>
      </span>
    )
  }
}

export default Modal
