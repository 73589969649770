import Button from '../../FormElement/Button/Button'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionSaveLastName } from '../../../store/actions'

function TypeName({ onEnter }) {
  const lastName = useSelector(({ me }) => me.lastName)

  const [val, setVal] = useState(lastName || '')

  const trimmedValue = val.trim()
  const dispatch = useDispatch()

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <div>
        <input
          aria-label="user name"
          data-testid="nick"
          className="input"
          type="text"
          placeholder="Вася Пупкин"
          value={val}
          onChange={(e) => {
            setVal(e.target.value)
          }}
        />
      </div>
      <div className="m-t-md">
        <Button
          disabled={trimmedValue.length < 3}
          onClick={() => {
            dispatch(actionSaveLastName({ lastName: trimmedValue }))
            onEnter(trimmedValue)
          }}
          dataTestid="addme"
        >
          введите ваше имя
        </Button>
      </div>
    </form>
  )
}

export default TypeName
