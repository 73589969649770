import React from 'react'
import PlayerList from '../../App/PlayerList/PlayerList'
import { useDispatch, useSelector } from 'react-redux'
import { actionSetDiary } from '../../../store/actions'

function DiaryPlayerList({ me, players, object }) {
  const dispatch = useDispatch()
  const { objectPlayerId, subjectPlayerId } = useSelector(
    ({ diary: { current } }) => current
  )

  return (
    <div>
      <PlayerList
        me={me}
        players={players}
        active={object ? objectPlayerId : subjectPlayerId}
        onClick={({ id }) => {
          dispatch(
            actionSetDiary({
              [object ? 'objectPlayerId' : 'subjectPlayerId']: id,
            })
          )
        }}
      />
    </div>
  )
}

export default DiaryPlayerList
