export const DIARY_ACTION_DEFEND = 'defend'
export const DIARY_ACTION_SUSPECT = 'suspect'
export const DIARY_ACTION_PUT_TO_VOTE = 'put_to_vote'
export const DIARY_ACTION_VOTE = 'vote'

export const DIARY_ACTIONS = {
  [DIARY_ACTION_DEFEND]: 'защищает',
  [DIARY_ACTION_SUSPECT]: 'подозревает',
  [DIARY_ACTION_PUT_TO_VOTE]: 'ставит на голосование',
  [DIARY_ACTION_VOTE]: 'голосует за',
}
