import React from 'react'
import { ROLE_CITIZEN_NAME, ROLE_MAFIA, ROLES } from '../../../config/roles'
import Player from './Player/Player'
import { useSelector } from 'react-redux'

function PlayerList({ players, me, onClick, active }) {
  const showRoles = useSelector(({ diary }) => diary.showRoles)

  // const rolesInObj = players.reduce((acc, { role }) => {
  //   if (role) {
  //     if (acc[role]) {
  //       acc[role]++
  //     } else {
  //       acc[role] = 1
  //     }
  //   }
  //
  //   return {
  //     ...acc,
  //   }
  // }, {})

  const haveRoles = !!players.find(({ role }) => !!role)

  return (
    <>
      <div data-testid="userlist" className="m-t-md list is-hoverable">
        {players.map((player, ind) => {
          let role

          if (me) {
            const playerIsMe = player.id === me.id

            if (haveRoles && showRoles) {
              if (
                playerIsMe ||
                (player.role === me.role && me.role === ROLE_MAFIA)
              ) {
                role = player.role ? ROLES[player.role].name : ROLE_CITIZEN_NAME
              } else {
                role = '?'
              }
            }
          }

          return (
            <Player
              key={`${player.id}.${player.name}`}
              player={player}
              ind={ind}
              showRoleName={role}
              active={active ? active.includes(player.id) : false}
              onClick={
                onClick &&
                (() => {
                  onClick({ id: player.id })
                })
              }
            />
          )
        })}
      </div>
    </>
  )
}

export default PlayerList
